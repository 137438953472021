/* You can add global styles to this file, and also import other style files */
@import 'icons';
@import 'barcode.scss';

html,
body {
  height: 100%;
  overflow: initial;
}

//initial loader
.parent {
  background: #f9f9f9;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.preloader {
  font-family: "Russo One", sans-serif;
  position: absolute;
  width: 255px;
  height: 100px;
}

#text {
  text-transform: uppercase;
  animation: stroke 5s infinite alternate;
  stroke-width: 2;
  stroke: #000;
  font-size: 140px;
}

@keyframes stroke {
  0% {
    fill: rgba(72, 138, 20, 0);
    stroke: #000;
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 1;
  }

  70% {
    fill: rgba(72, 138, 20, 0);
    stroke: #000;
  }

  80% {
    fill: rgba(72, 138, 20, 0);
    stroke: #000;
    stroke-width: 2;
  }

  100% {
    fill: #000;
    stroke: #42A5F5;
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

.content-wrapper {
  display: -ms-flexbox;
  display: flex;
  min-height: calc(100vh - 37px);
  -ms-flex-direction: column;
  flex-direction: column;
}

.bottom-wrapper {
  position: relative;
  z-index: 1;
}


.cd-horizontal-timeline .events ol li a {

  &.survey {
    &::after {
      content: "\f1ea" !important;
    }
  }

  &.lost-transmission {
    &::after {
      content: "\f4b3" !important;
    }
  }

  &.diagnostic-procedure {
    &::after {
      content: "\f022" !important;
    }
  }

  &.iot-measurement {
    &::after {
      content: "\f012" !important;
    }
  }

}

.p-tooltip,
.tooltip {
  .tooltip-title {
    display: inline-block;

    .module-svg {
      float: right;
    }
  }

  .bs-tooltip-bottom.tooltip-arrow {
    margin-top: 7px;
  }

  .final-info {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
  }
}